<template>
    <router-view />
</template>
<script>
    import "ant-design-vue/dist/antd.css"; 
    import Vue from "vue";
    import ant from "ant-design-vue";
    Vue.use(ant); 
    export default {
        name: "Error",
        data() {
            return {                 
            };
        },
        methods: {
            
        },
        created: function () {

        },
        mounted: function () {
            
        },
        computed: {
        },
        components: {}
    };
</script> 